export default {
  head_title_pt: 'Rotesma - Solicite um orçamento',
  head_title_en: 'Rotesma - Solicite um orçamento',
  head_title_es: 'Rotesma - Solicitar presupuesto',
  title_pt: 'Solicite um orçamento',
  title_en: 'Solicite um orçamento',
  title_es: 'Solicitar presupuesto',
  description_pt: 'Complete o formulário abaixo, nossa equipe entrará em contato com você para compreender as suas necessidades e elaborar a melhor solução.',
  description_en: 'Complete o formulário abaixo, nossa equipe entrará em contato com você para compreender as suas necessidades e elaborar a melhor solução.',
  description_es: 'Complete el siguiente formulario, nuestro equipo se comunicará con usted para comprender sus necesidades y desarrollar la mejor solución.'
}
