import React, { useContext, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { LoadingContext, LocationContext } from '@layouts'
import { getLanguage } from '@components/Translation'
import { Button, Footer, Grid, Head, Translate } from '@components'
import InputMask from 'react-input-mask'
import locale from '@data/locales/request-quote'
import formsLocale from '@data/locales/forms'
import ReCAPTCHA from 'react-google-recaptcha'
import { Checkbox, Input, File, Recaptcha } from '@pages/trabalhe-conosco'
import api from '@api'
import * as st from '@assets/styl/RequestAQuote.module.styl'

export default ({ location: { pathname } }) => {
  const [data, setData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    city: '',
    uf: '',
    size: '',
    file: null,
    privacy: false
  })
  const [error, setError] = useState('')
  const fileRef = useRef<HTMLInputElement>()
  const recaptchaRef = useRef<ReCAPTCHA>()
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const location = useContext(LocationContext)
  const language = getLanguage(location)
  const success = () => {
    if (language === 'es')
      navigate('/gracias/')
    else if (language === 'en')
      navigate('/thank-you/')
    else
      navigate('/obrigado/')
  }
  const submit = (recaptcha: string) => {
    toggleLoading(true)
    const formData = new FormData()
    for (const key in data)
      formData.append(key, data[key])
    formData.append('recaptcha', recaptcha)
    api('post', `leads/budget?lang=${getLanguage(location)}`, formData)
    .then(data => {
      if (data.code === 0)
        success()
      else {
        recaptchaRef.current.reset()
        let error = ''
        for (const key in data.errors) {
          error = data.errors[key]
          break
        }
        setError(error)
        toggleLoading(false)
      }
    })
  }
  const { toggleVisible: toggleLoading } = useContext(LoadingContext)

  return <>
    {Translate(locale, 'head_title', title => <Head title={title} path={pathname} />)}

    <section className={st.core} id="form-section">
      <Grid>
        <div className={st.wrapper}>
          <h1>{Translate(locale, 'title')}</h1>
          <p>{Translate(locale, 'description')}</p>
          <form onSubmit={(e) => { e.preventDefault(); recaptchaRef.current.executeAsync().then((value) => submit(value)) }}>
            <Input name="name" value={data.name} onChange={onChange} />
            <Input name="company" value={data.company} onChange={onChange} />
            <InputMask mask={language === 'es' ? '999 999999' : data.phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'} maskChar={null} value={data.phone} onChange={onChange}>
              {(inputProps) => <Input {...inputProps} name="phone" type="tel" pattern={language === 'es' ? '.{9,10}' : '.{14,15}'} />}
            </InputMask>
            <Input name="email" type="email" value={data.email} onChange={onChange} />
            <Input name="city" className={st.left} value={data.city} onChange={onChange} />
            <Input name="uf" value={data.uf} onChange={onChange} />
            <Input name="size" className={st.clear} value={data.size} onChange={onChange} />
            <File name="file" placeholder="file_project" value={data.file} inputRef={fileRef} onChange={onChange} className={st.file} required={false} />
            <Checkbox name="privacy" onChange={(e) => onChange({ ...e, value: e.target.checked })} className={st.checkbox}>{Translate(formsLocale, 'privacy')}</Checkbox>
            {error && <div className={st.error}>{error}</div>}
            <div>
              <Recaptcha inputRef={recaptchaRef} className={st.left} />
              <Button className={st.btn}>{Translate(formsLocale, 'send') as unknown as string}</Button>
            </div>
            <div className={st.clear}></div>
          </form>
        </div>
      </Grid>
    </section>

    <Footer />
  </>
}